<style lang="scss" scoped>
@import "./MainHeader.scss";
</style>

<template>
  <div class="biote header-wrapper">
    <LayoutCentered class="header justify-between">
      <MainSidebarToggle class="sidebar-toggle" />
      <a @click="backToLandingPage">
        <img class="site-logo" :src="getBrandLogo.img" :alt="getBrandLogo.alt" :title="getBrandLogo.title" />
      </a>
      <UserSessionActions v-if="isPracticeUserView && isAuthenticated" class="session-actions" />
    </LayoutCentered>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VueCookies from "vue-cookies";
import { get, isEmpty } from "lodash";
import LayoutCentered from "@/components/common/LayoutCentered.vue";
import MainSidebarToggle from "@/components/common/MainSidebarToggle.vue";
import UserSessionActions from "@/components/common/UserSessionActions.vue";
import userMe from "@/services/user-me";
// import authService from "@/auth/auth.service";

export default {
  name: "NewMainHeader",
  components: {
    LayoutCentered,
    MainSidebarToggle,
    UserSessionActions,
  },
  data() {
    return {
      applyLoyalty: false,
      total: 0,
      activeDrawer: false,
      // isPatient: 'false',
      headerBackground: "",
      userType: "",
      practiceId: "",
      contentColor: "",
      landingPageType: "",
      isDosing: false,
      isPracticeBuilder: false,
      isAuthenticated: false
    };
  },
  computed: {
    ...mapGetters("accountsModule", ["getOrgDetails"]),
    ...mapGetters("locationsModule", ["getCurrentLocationDetails"]),
    ...mapGetters([
      "getCurrentUser",
      "getCartItems",
      "getSelectedShippingRates",
      "getCurrentPurchase",
      "getEmptyCart",
      "getCheckoutStep",
      "getBrandDetails",
      "isLabTestPageLoaded",
      "isBHRTPageLoaded",
      "getLandingPageType",
      "getMyProfileStep",
      "getSelectedVendor",
    ]),
    ...mapGetters("usersModule", ["isLoggedIn", "getUserAuthDetails", "getMyInfo"]),
    isCurrentLocationActive() {
      return this.getCurrentLocationDetails ? this.getCurrentLocationDetails.isActive : false;
    },
    isSimpatraAdmin() {
      const { isSimpatraAdmin } = userMe.getMyRoles();
      return isSimpatraAdmin;
    },
    isPracticeUserView() {
      const selectedVendor = get(this, "getSelectedVendor", null);
      const parentRoute = this.$route.matched[this.$route.matched.length - 2];
      if (isEmpty(selectedVendor) && parentRoute && !parentRoute.meta.simpatraReports) return true;
      return this.isLoggedIn;
    },
    getBrandLogo() {
      const logo = require("@/assets/biote/biote-rx-logo-color.png");
      return {
        img: logo,
        alt: "biote-logo",
        title: "biote marketplace",
      };
    },
  },
  async mounted() {
    const { isAuthenticated } = await this.$auth;
    this.isAuthenticated = isAuthenticated;
    this.userType = localStorage.userType === "practiceUser" ? localStorage.userType : "orgUser";

    const whitelistDosingPages = ["DosingHome", "DosingPatients", "PatientDetails"];
    const whitelistPracticePages = [
      "PracticeBuilderDashboard",
      "Settings",
      "OnlineStoreMain",
      "PatientOutReachApps",
      "PracticeBuilderLandingPagesDashboard",
      "SocialMediaPageDashboard",
    ];

    this.isDosing = whitelistDosingPages.includes(this.$route.name);
    this.isPracticeBuilder = whitelistPracticePages.includes(this.$route.name);

    this.headerBackground = localStorage.color ? localStorage.color : "#374458";
    this.practiceId = localStorage.practiceId ? localStorage.practiceId : "";
    this.contentColor = localStorage.contentColor ? localStorage.contentColor : "#f05a28";
    const USER_TYPE = this.userType === "practiceUser" ? "patientUser" : "orgUser";
    await this.commitUserType(USER_TYPE);
    if (window.location.hostname === "checkout.medcenter.health") {
      const data = {
        orgId: this.practiceId,
      };
      await this.fetchBrandingDetails(data);
    }

    if (this.userType === "practiceUser" && localStorage.landingPage !== "bhrt") {
      const productArrayString = VueCookies.get("productArrayString");
      const getItems = JSON.parse(productArrayString);
      await this.commitItemToCart(getItems);
      await this.setEmptyCart(false);
    }
  },
  methods: {
    ...mapActions({
      commitItemToCart: "marketplaceProduct/commitItemToCart",
      setEmptyCart: "setEmptyCart",
      fetchBrandingDetails: "fetchBrandingDetails",
      commitUserType: "commitUserType",
    }),
    slideOutDrawer() {
      if (!this.isCurrentLocationActive) return false;
      this.activeDrawer = !this.activeDrawer;
    },
    backToLandingPage() {
      const { isVendorUser, isSimpatraAdmin } = userMe.getMyRoles();
      const selectedVendor = localStorage.getItem("selected_vendor");
      const currentLocation = localStorage.getItem("currentLocation");
      const isVendorView = !["", null, undefined].includes(selectedVendor);
      if ((isVendorView && isSimpatraAdmin) || isVendorUser) {
        if (!this.$route.meta.checkVendorAccessibility) return;
        const vendorId = isSimpatraAdmin ? selectedVendor : get(this.getMyInfo.sim.details, "id", "");
        this.$router.push({
          name: "VendorOrderList",
          params: {
            vendorId: vendorId
          }
        })
      } else {
        if (this.$route.name === "DosingHome") return;
        this.$router.push(`/dashboard/${currentLocation}/patient-chart/`);
      }
    },
    backToLabTest() {
      if (this.isLabTestPageLoaded || this.$route.name === "Product") {
        const iframe = "ifr2";
        this.postMessage(iframe);
        window.location = `${process.env.VUE_APP_LABTEST_ROOT}/simpatra`;
      }
    },
    backToBhrtPage() {
      if (this.isBHRTPageLoaded) {
        const iframe = "ifr3";
        this.postMessage(iframe);
        window.location = `${process.env.VUE_APP_BHRT_PORTAL}/simpatra`;
      }
    },
    postMessage(iframe) {
      const userEmail = localStorage.email !== "" ? localStorage.email : this.getCurrentUser.email;
      const postMsg = {
        access_token: localStorage.access_token,
        id_token: localStorage.id_token,
        email: userEmail,
        exp: localStorage.exp,
        userInfo: this.getCurrentUser,
      };
      this.postCrossDomainMessage(postMsg, iframe);
    },
    postCrossDomainMessage(msg, iframe) {
      const win = document.getElementById(iframe).contentWindow;
      const PATH = iframe === "ifr3" ? `${process.env.VUE_APP_BHRT_PORTAL}` : `${process.env.VUE_APP_LABTEST_ROOT}`;
      win.postMessage(msg, PATH);
    },
    async handleLogin() {
      // await authService.login();
    },
  },
};
</script>
